@import '~/assets/global/main';

$title-font-color: $basic-headlings-color;
$kicker-font-color: $basic-headlings-color;
$subtitle-font-color: $basic-body-color;

.ContentTitle {
  padding-top: 80px;
  @include conditional-bottom-margin(50px);

  &__inner {
    @include standard-layout-column;
    text-align: center;
    padding: 0 15px;
  }

  &__kicker {
    font-size: 16px;
    color: $kicker-font-color;
    margin-bottom: 15px;
  }

  &__title {
    color: $brand-dark-purple;
    font-weight: 500;
    line-height: 1.25em;
    margin: 0 0 15px 0;
    font-size: 47px;
    margin-bottom: 24px;
    margin-top: 54px;
    margin-left: auto;
    margin-right: auto;
    max-width: 720px;

    @include for-size-large {
      font-size: 43px;
      letter-spacing: -0.02em;
      margin-top: 24px;
      max-width: 100%;
    }
  }

  &__subtitle {
    @include for-size-large {
      padding-right: 0;
    }

    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
    max-width: 720px;
  }
}

.condensed {
  @include for-size-second-most-used {
    padding-top: 60px;

    .SimpleTitleContent__title {
      font-size: 45px;
      margin-top: 40px;
      margin-bottom: 20px;
      max-width: 90%;
    }

    .SimpleTitleContent__subtitle {
      max-width: 90%;

      p {
        margin-bottom: 0;
      }
    }

    @include conditional-bottom-margin(40px);
  }
}