@import '../../../../assets/global/main';

.FaqSection {
  margin-bottom: 60px;

  &__inner {
    @include standard-layout-column;
    display: flex;
    row-gap: 20px;
    flex-direction: column;
  }

  &__faqLabel {
    font-size: 22px;
    color: $title-body-color;
    margin: 30px 0 15px 0;
    padding: 0 15px;
  }

  &__faqQuestionContainer {
    appearance: none;
    padding: 15px;
    border: none;
    border-bottom: 1px solid $divider-color;
    width: 100%;
    background-color: transparent;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
    outline: none;

    &.faqExpanded {
      .FaqSection__faqAnswer {
        height: auto;
        max-height: 150px;
        transition-timing-function: ease-in;
        transition: max-height 1s, padding 1s;
        padding: 16px 0;
      }

      .FaqSection__faqContainerIcon {
        background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='17' height='17' viewBox='0 0 17 17'%3E%3Cg%3E%3C/g%3E%3Cpath d='M15 8v1h-13v-1h13z' fill='%23576370' /%3E%3C/svg%3E");
      }
    }
  }

  &__faqQuestion {
    font-size: 18px;
    color: $basic-body-color;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: left;
  }

  &__faqContainerIcon {
    height: 40px;
    width: 40px;
    background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='17' height='17' viewBox='0 0 17 17'%3E%3Cg%3E%3C/g%3E%3Cpath d='M16 9h-7v7h-1v-7h-7v-1h7v-7h1v7h7v1z' fill='%23576370' /%3E%3C/svg%3E");
    background-position: center;
    background-repeat: no-repeat;
    display: block;
  }

  &__faqAnswer {
    justify-content: flex-start;
    color: $basic-body-color;
    max-height: 0;
    overflow: hidden;
    font-size: 15px;
    text-align: left;
    transition-timing-function: ease-in;
    transition: max-height 0.3s, padding 0.3s;

    a {
      color: $brand-saturated-purple;
      font-weight: 600;
    }
  }
}