@import '../../../assets/global/main';

.BlogArticleList {
  &__listElementLabel {
    font-weight: bolder;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    opacity: 0.6;
    display: flex;
    margin-top: -4px;
  }

  &__menuSlot {
    width: 200px;
  }

  &__listContainer {
    list-style: none;
    margin: 0 0 25px 0;
    padding: 0;
  }

  &__listElementAnchor {
    display: flex;
    font-size: 14px;
    width: 100%;

    &:hover,
    &:active {
      text-decoration: underline;

      > span {
        margin-left: 15px;
      }
    }
  }

  &__listElement {
    display: flex;
    line-height: 1.5em;
    padding: 4px 0;
  }

  &__readMore {
    @include soft-transition;
    margin-left: 5px;
    display: inline-flex;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'%3E%3Cdefs/%3E%3Cpath fill='%23fdce1c' d='M314 216H12a12 12 0 00-12 12v56a12 12 0 0012 12h302v46a24 24 0 0041 17l86-86a24 24 0 000-34l-86-86a24 24 0 00-41 17v46z'/%3E%3C/svg%3E");
    height: 14px;
    width: 19px;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
    top: 5px;
  }
}
