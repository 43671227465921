@import '../../../../assets/global/main';

$element-box-shadow: 0 30px 60px 0 rgba(0, 11, 40, 0.14);

.PricingPlans {
  margin: 15px;

  &__inner {
    @include standard-layout-column;
  }

  &__row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  &__singlePlan {
    @include soft-transition;
    width: calc(33% - 32px);
    border-radius: 8px;
    background-color: #fff;
    justify-content: space-between;
    margin-bottom: 30px;
    border: 1px solid $border-medium;

    @include for-size-large {
      width: calc(50% - 32px);
    }

    @include for-size-big {
      width: 100%;
    }
  }

  &__singlePlanInner {
    position: relative;
    padding: 40px 40px 30px;
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__singlePlanTitle {
    font-size: 24px;
    font-weight: 700;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: $brand-dark-purple;
    line-height: 1;
    margin-bottom: 18px;
  }

  &__singlePlanCurrencySymbol {
    font-size: 40px;
    color: $basic-headlings-color;
    line-height: 1;
  }

  &__singlePlanPrice {
    font-size: 40px;
    color: $basic-headlings-color;
    line-height: 1;
    letter-spacing: -0.02em;
  }

  &__singlePlanPriceCustom {
    font-size: 32px;
    color: $basic-headlings-color;
    line-height: 1;
    letter-spacing: -0.02em;
  }

  &__singlePlanPriceAnnotation {
    font-size: 28px;
    font-weight: 500;
    line-height: 1;
    color: #999;
    letter-spacing: -0.02em;
  }

  &__singlePlanPricing {
    margin-bottom: 6px;
  }

  &__singlePlanCtaContainer {
    width: 100%;
    margin: 15px 0;

    .PricingPlans__singlePlanCtaButton {
      padding: 11.5px 17px;
    }
  }

  &__singlePlanSymbolHolder {
    @include unselectable;
    height: 110px;
    width: 110px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px auto;
    border-radius: 50%;
  }

  &__singlePlanRibbon {
    @include unselectable;
    position: absolute;
    top: 12px;
    right: 4px;
    color: #fff;
    background-color: $brand-yellow;
    padding: 0 20px 0 14px;
    transform: rotate(90deg);
    height: 40px;
    font-weight: 600;
    font-size: 13px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 3px;

    &:after {
      content: ' ';
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 20px 12px 20px 0;
      border-color: transparent #fff transparent transparent;
    }

    // Change color of ribbon triangle on parent hover
    &:hover,
    &active {
      .PricingPlans__singlePlanRibbon {
        &:after {
          border-color: transparent #fff transparent transparent;
        }
      }
    }
  }

  &__singlePlanFeaturesList {
    margin: 0;
    list-style: none;
    padding: 10px 20px 20px;
    width: 100%;
  }

  &__singlePlanFeatureListElement {
    position: relative;
    margin: 16px 0;
    line-height: 21px;

    a {
      color: $brand-saturated-purple;
      font-weight: 600;
    }

    &:hover {
      .PricingPlans__singlePlanFeatureListElementTooltip {
        display: flex;
      }
    }
  }

  &__singlePlanFeatureListElementLabel {
    cursor: pointer;
    font-size: 14px;
    color: $text-normal;
    display: block;
    -webkit-tap-highlight-color: transparent;

    &:empty {
      min-height: 25px;
      visibility: hidden;
    }
  }

  &__singlePlanFeatureListElementTooltip {
    font-size: 14px;
    position: absolute;
    background: $basic-headlings-color;
    line-height: 1.3;
    padding: 8px 24px;
    border-radius: 6px;
    width: 300px;
    left: 50%;
    margin-left: -150px;
    z-index: 5;
    display: none;
    pointer-events: none;
    margin-top: -60px;
    height: 52px;
    color: #fff;
    box-shadow: 0px 5px 21px 8px rgba(0, 0, 0, 0.18);
    align-items: center;

    &:empty {
      visibility: hidden;
    }

    &:after {
      position: absolute;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 10px 10px 0 10px;
      border-color: $basic-headlings-color transparent transparent transparent;
      content: ' ';
      display: block;
      left: 50%;
      bottom: -10px;
      margin-left: -10px;
    }
  }

  &__singlePlanFeaturesTitle {
    font-size: 14px;
    line-height: 1.4em;
    color: $basic-body-color;
    margin-bottom: 15px;
    text-align: center;
    min-height: 59.5px;
  }

  &__pricingSwitcher {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-bottom: 30px;

    @include for-size-large {
      align-items: center;
      margin-bottom: 50px;
    }
  }

  &__pricingSwitcherContainer {
    margin: 0;
    padding: 0;
    list-style: none;
    border: 2px solid $brand-saturated-purple;
    border-radius: 100px;
  }

  &__pricingType {
    @include unselectable;
    display: inline-flex;
    padding: 8px 20px;
    font-weight: bold;
    font-size: 14px;
    line-height: 1;
    color: $brand-saturated-purple;
    background-color: transparent;
    cursor: pointer;

    &.isPriceTypeSelected {
      background-color: $brand-saturated-purple;
      color: #fff;
      border-radius: 100px;

      &:nth-child(1) {
        border-radius: 100px 0 0 100px;
      }

      &:nth-child(2) {
        border-radius: 0 100px 100px 0;
      }
    }
  }

  &__promoMessage {
    font-weight: 600;
    color: $basic-headlings-color;
    margin-top: 10px;
    padding-right: 10px;

    @include for-size-large {
      padding: 0;
    }
  }
}

.perk {
  display: flex;
  align-items: flex-start;
  gap: 6px;

  img {
    margin-top: 2px;
  }
}

.priceDescription {
  font-size: 14px;
  color: $basic-body-color;
  text-align: center;
  margin-bottom: 4px;
  font-weight: 600;
}

.hidden {
  visibility: hidden;
}

.price {
  width: fit-content;
  margin: 0 auto;
}

.compactPricing {
  margin-bottom: -8px;
}