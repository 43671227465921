@import '../../../assets/global/main';

.FooterSocials {
  &__socialListElement {
    display: inline-flex;
    height: 45px;
    width: 45px;
    margin: 10px;
    background-color: $brand-yellow;
    border-radius: 50%;
  }

  &__socialListElementAnchor {
    @include unselectable;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
  }

  &__socialListElementIcon {
    height: 17px;
    width: 17px;
  }

  &__socialListContainer {
    margin: 0;
    padding: 0;
  }
}
