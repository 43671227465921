@import '~/assets/global/main';

.menuSlot {
  width: 200px;

  @include for-size-large {
    width: 100%;
  }
}

.listContainer {
  list-style: none;
  margin: 0 0 25px 0;
  padding: 0;
}

.listElement {
  margin: 0;
}

.listElementAnchor {
  display: flex;
  font-size: 14px;
  padding: 2px 0;

  @include for-size-large {
    padding: 5px 0;
  }

  &:hover,
  &:focus,
  &:active {
    text-decoration: underline;
  }
}

.listElementLabel {
  font-weight: bolder;
  font-size: 12px;
  margin-bottom: 0;
  letter-spacing: 0.1em;
  opacity: 0.6;
  display: flex;
  text-transform: uppercase;

  &.withTopMargin {
    margin-top: 32px;
  }
}